import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const SectionHero = styled(props => <BackgroundImage {...props} />)`
  position: relative;
  min-height: 100vh;

  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
  }
`

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
`

const OverlayContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 767px;
  margin: auto 6rem;
  padding: 0.8rem 3rem;
  text-align: center;

  @media screen and (max-width: 767px) {
    margin: 0 0.5rem;
    padding: 1.5rem 1rem;
  }
`

const HeroImage = ({ title, featuredImage }) => {
  return (
    <SectionHero
      Tag="section"
      fluid={featuredImage.localFile.childImageSharp.fluid}
      backgroundColor="#000"
    >
      <Overlay>
        <OverlayContent>
          <h1
            className="title is-size-2 has-centered-text has-text-weight-light"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </OverlayContent>
      </Overlay>
    </SectionHero>
  )
}

export default HeroImage
