import React from 'react'
import VideoEmbed from './VideoEmbed'

const VideoSection = ({ videos }) => {
  return (
    <section id="videos">
      {videos
        ? videos.map(video => (
            <VideoEmbed
              videoTitle={video.video_title}
              videoSrcURL={video.video_source_url}
              key={video.video_source_url}
            />
          ))
        : false}
    </section>
  )
}

export default VideoSection
