import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Tile = styled.article`
  display: flex !important;
  flex-direction: column;
  position: relative;

  picture {
    img {
      transition: all 2s ease-out !important;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
`

const ImageTile = ({ image }) => {
  return (
    <Tile className="column is-one-third event-tile">
      <Img fluid={image.childImageSharp.fluid} />
    </Tile>
  )
}

export default ImageTile
