/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import HeroImage from '../components/Content/Hero/HeroImage'
import ContentRows from '../components/Content/ContentRows'
import GalleryGrid from '../components/Content/GalleryGrid'
import VideoSection from '../components/Content/VideoSection'

const StyledSection = styled.section`
  @media screen and (max-width: 767px) {
    .columns {
      display: flex;
      flex-flow: column-reverse;

      &:first-child {
        margin-top: 3rem !important;
      }
    }

    .columns:nth-child(2n + 1) {
      flex-flow: column;
    }
  }
`

const EventTemplate = ({ data }) => {
  const { wordpressWpEvent: post } = data

  const { featured_image, content_rows, image_gallery, videos } = post.acf

  return (
    <Layout
      title={post.title}
      themeOption={data.wordpressAcfOptions}
      categoryColors={data.allWordpressAcfCategories}
      imageUrl={featured_image.source_url}
      excerpt={post.excerpt}
    >
      <HeroImage title={post.title} featuredImage={featured_image} />
      <StyledSection>
        <ContentRows rows={content_rows} />
      </StyledSection>
      <VideoSection videos={videos} />
      <GalleryGrid images={image_gallery} />
    </Layout>
  )
}

export default EventTemplate

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    wordpressWpEvent(id: { eq: $id }) {
      title
      excerpt
      acf {
        featured_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          source_url
        }
        content_rows {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
        image_gallery {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 768, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        videos {
          video_source_url
          video_title
        }
      }
    }
    wordpressAcfOptions {
      options {
        theme {
          name
          slug
          term_id
        }
      }
    }
    allWordpressAcfCategories {
      edges {
        node {
          acf {
            theme_color
            theme_text_color
          }
          wordpress_id
        }
      }
    }
  }
`
