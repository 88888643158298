import React from 'react'
import styled from 'styled-components'
import ImageTile from './ImageTile'

const GallerySection = styled.section`
  @media screen and (min-width: 768px) {
    margin-top: 3rem !important;
  }
`

// TODO:: Don't recreate each element on select change
// Attempt to store the created tiles in an object, and only
// reference them from there?
const GalleryGrid = ({ images }) => {
  return (
    <GallerySection className="columns is-multiline is-gapless">
      {images &&
        images.map(image => {
          return <ImageTile image={image.localFile} key={image.id} />
        })}
    </GallerySection>
  )
}

export default GalleryGrid
