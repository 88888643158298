import React from 'react'
import styled from 'styled-components'

const VideoWrapper = styled.div`
  max-width: 800px;
  margin: 6rem auto;
`

const Video = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

const VideoEmbed = ({ videoSrcURL, videoTitle }) => {
  const videoPlayerURL = videoSrcURL.replace(
    'https://vimeo.com/',
    'https://player.vimeo.com/video/'
  )

  return (
    <VideoWrapper>
      <Video className="video">
        <iframe
          src={videoPlayerURL}
          title={videoTitle}
          width={800}
          height={450}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Video>
    </VideoWrapper>
  )
}

export default VideoEmbed
